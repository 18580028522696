document.addEventListener('DOMContentLoaded', function () {
	objectFitImages();
	setUA();
	toggleGnav();
	hidePagetop();
	smoothScroll();
	pagerNumFormat();
	if ($('.js-articles').length) {
		carouselArticles();
	}
	if ($('.js-more').length) {
		slideArticle()
	}
})
window.addEventListener('load', function () {
	AOS.init({
		offset: 300,
		// delay: 100,
		duration: 1000,
		once: true,
	});

})
window.addEventListener('scroll', function () {
});


function setUA() {
	//ua判定
	var ua = UAParser()
	var HTML = document.getElementsByTagName('html')[0];
	var uaString = {
		browserName: ua.browser.name.toLowerCase().replace(' ', '-'),
		browserVersion: ua.browser.major,
		browserEngine: ua.engine.name.toLowerCase().replace(' ', '-'),
		osName: ua.os.name.toLowerCase().replace(' ', '-'),
		type: (typeof ua.device.type !== 'undefined') ? ua.device.type.toLowerCase().replace(' ', '-') : 'laptop'
	}
	HTML.dataset.browser = uaString.browserName
	HTML.dataset.browserversion = uaString.browserVersion
	HTML.dataset.browserengine = uaString.browserEngine
	HTML.dataset.os = uaString.osName
	HTML.dataset.type = uaString.type
}
function smoothScroll() {
	$('a[href^="#"]').click(function () {
		// スクロールの速度
		var speed = 500; // ミリ秒
		// アンカーの値取得
		var href = $(this).attr("href");
		// 移動先を取得
		var target = $(href == "#" || href == "" ? 'html' : href);
		// 移動先を数値で取得
		var position = target.offset().top;
		// スムーススクロール
		$('body,html').animate({ scrollTop: position }, speed, 'swing');
		return false;
	});
}
function toggleGnav() {
	$('.js-menu').click(function () {
		$(this).toggleClass('is-active')
		$('.js-gnav').toggleClass('is-active')
		return false;
	})
}
function hidePagetop() {
	$(window).scroll(function () {
		if ($(window).scrollTop() < 200) {
			$('.js-pagetop').stop().fadeTo(200, 0, function () {
				$('.js-pagetop').hide();
			});
		} else {
			$('.js-pagetop').stop().fadeTo(300, 1);
		}
	}).scroll();
}

function carouselArticles() {
	var maxSlideNum = 0,
		thisSlideIndex = 0;
	var swiper = new Swiper(".js-articles", {
		slidesPerView: 1,
		slidesPerGroup: 1,
		spaceBetween: 0,
		// centeredSlides: true,
		//loop: true,
    autoplay: {
      delay: 6000,
    },
		pagination: {
			el: ".c-carousel__main .swiper-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".js-articles_next",
			prevEl: ".js-articles_prev",
		},
		breakpoints: {
			// when window width is >= 480px
			600: {
				slidesPerView: 2,
				slidesPerGroup: 2,
			},
			// when window width is >= 640px
			900: {
				slidesPerView: 3,
				slidesPerGroup: 3,
			}
		},
		on: {
			init: function () {
			},
			breakpoint: function (breakpointParams) {
				var slideGroupCount;
				if (breakpointParams.currentBreakpoint >= 900) {
					slideGroupCount = 3;
				} else if (breakpointParams.currentBreakpoint >= 600) {
					slideGroupCount = 2;
				} else {
					slideGroupCount = 1;
				}
				// Swiper起動時に実行される
				var slideNum = []; // スライドの最大枚数を取得するための配列

				/*
					下記の繰り返しで swiper-slide の data属性に付与されている swiper-slide-index を取得して
					配列に格納していく、 Math.maxで配列の中に格納されている数値の中で一番数が大きいものを maxSlideNum に入れる
				*/
				var slideLength = document.querySelectorAll('.js-articles .swiper-slide').length;
				// loop が true かチェックする
				// loop が true の場合、loopedSlidesが1になるのでこの分岐で確認が出来る false の場合は undefined が返ってくる
				if (this.loopedSlides) {
					maxSlideNum = $('.js-articles .swiper-slide:not(.swiper-slide-duplicate)').length;
				} else {
					maxSlideNum = slideLength;
				}
				// スライドの最大枚数を出力
				document.querySelector('.js-articles_Maxnum').innerHTML = '0' + Math.ceil(maxSlideNum / slideGroupCount);

				// 最初の数値を出力（スライドの初期位置が違う場合は、ここを変更する）
				document.querySelector('.js-articles_currentNum').innerHTML = '0' + 1;
			},
			transitionStart: function () {
				/* 
				 * スライドが切り替わった時に実行される
				 * インデックスは 0 スタートなので 1 を足した数値にする
				 */
				thisSlideIndex = [].slice.call(document.querySelector('.c-carousel__main .swiper-pagination').querySelectorAll('.c-carousel__main .swiper-pagination-bullet')).indexOf(document.querySelector('.c-carousel__main .swiper-pagination-bullet-active')) + 1;
				console.log(thisSlideIndex)
				document.querySelector('.js-articles_currentNum').innerHTML = '0' + thisSlideIndex;
			},
		}
	});

}

function slideArticle() {
	if ($('.js-moreitem').find('li').length < 6) {
		$('.js-more').hide()
	}
	$('.js-more a').click(function () {
		$('.js-moreitem').find('li').slideDown();
		$(this).parent('.js-more').slideUp();
		return false;
	})
}

function pagerNumFormat() {
	$('.wp-pagenavi').each(function(index,el){
		$(el).find('.current').text(("00" + $(el).find('.current').text()).slice( -2 ))
		$(el).find('.total').text(("00" + $(el).find('.total').text()).slice( -2 ))
	})
}